import * as TapableHooks from 'tapable'
import { AsyncParallelHook, AsyncSeriesBailHook, AsyncSeriesWaterfallHook } from 'tapable'
import { BabyElement, Plugin, BaseExtend, PluginArrayExtend } from './types'

class Babyblue<PA extends readonly Plugin[]> {
  __PLUGIN_ID__ = ''

  tapable = TapableHooks

  hooks: (BaseExtend<PluginArrayExtend<PA>> & PluginArrayExtend<PA>)['hooks'] = {
    // 初始化, 生命周期内只执行一次
    bootstrap: new AsyncParallelHook<Babyblue<PA>>(['babyblue']),
    // 基础模块处理中间件, 串行依次返回列表
    middleware: new AsyncSeriesWaterfallHook(['elements']),
    // 运行, 每次 run 调用时触发, 有回调时终止返回
    run: new AsyncSeriesBailHook(['elements']),
    // 运行完成的后置处理
    afterRun: new AsyncParallelHook(['elements']),
  }

  methods: (BaseExtend<PluginArrayExtend<PA>> & PluginArrayExtend<PA>)['methods'] = {}

  private pluginId = 0

  private pluginMap: Record<string, { apply: boolean; plugin: Plugin }> = {}

  private bootstrapCalledList: Function[] = []

  /**
   * 构造器, 注册插件, 执行 init 完成初始 hook 拓展
   */
  constructor({ plugins = ([] as unknown) as PA }: { plugins?: PA } = { plugins: ([] as unknown) as PA }) {
    plugins.forEach(plugin => {
      if (typeof plugin === 'function' || typeof plugin === 'object') {
        if (typeof plugin === 'object') {
          const extend = plugin.init?.(this) || undefined
          Object.assign(this.hooks, extend?.hooks || {})
          Object.assign(this.methods, extend?.methods || {})
        }
      } else throw new Error(`Unexpected plugin ${plugin}`)

      const pluginID = (typeof plugin === 'object' ? plugin.id : '') || `${++this.pluginId}`
      if (this.pluginMap[pluginID]) throw new Error(`Same plugin id ${pluginID}`)

      this.pluginMap[pluginID] = { apply: false, plugin }
    })

    this.hooks.bootstrap.intercept({
      register: tap => {
        return {
          ...tap,
          fn: (...args: any[]) => {
            if (this.bootstrapCalledList.indexOf(tap.fn) > -1) {
              if (tap.type === 'promise') return Promise.resolve()
              if (tap.type === 'async') args[1](null)
              return
            }
            this.bootstrapCalledList.push(tap.fn)
            return tap.fn(...args)
          },
        }
      },
    })
  }

  /**
   * 初始化, 执行所有插件 install 完成自身逻辑的执行和注册, 实例级别只执行一次
   */
  public async bootstrap(): Promise<Babyblue<PA>> {
    Object.keys(this.pluginMap).forEach(id => {
      const { plugin } = this.pluginMap[id]!
      // 给每个插件传入自己的 plugin id
      if (!this.pluginMap[id]!.apply) {
        Object.defineProperty(this, '__PLUGIN_ID__', { get: () => id })
        if (typeof plugin === 'function') plugin(this)
        if (typeof plugin === 'object') plugin.apply(this)

        this.pluginMap[id]!.apply = true
      }
    })
    await this.hooks.bootstrap.promise(this)
    return this
  }

  /**
   *  执行函数, 支持通过 run hook 回调返回自定义结果
   */
  public async run<T>(
    element?: BabyElement<PluginArrayExtend<PA>['element']> | Array<BabyElement<PluginArrayExtend<PA>['element']>>,
  ): Promise<T> {
    // 自动调用一次初始化, 简化使用
    await this.bootstrap()

    const rawElementList = Array.isArray(element) ? element : element ? [element] : []

    const elements = await this.hooks.middleware.promise(rawElementList)

    const result = await this.hooks.run.promise(elements)

    await this.hooks.afterRun.promise(elements)

    return result
  }
}

export * from 'tapable'
export * from './types'
export default Babyblue
